import React,{ Fragment } from "react";
import BoxTitle from "../../box/title/BoxTitle";
import BoxSubTitle from "../../box/subTitle/BoxSubTitle";
import { LoadingDot } from "../../UI/spinners/LoadingSpinners";
import { useTranslation } from "react-i18next";

const UvIndexes = ({ data, typeId }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {data && (
        <Fragment>
          <BoxTitle
            icon={"im im-icon-Envelope-2"}
            title={data.index_name}
            styleIcon={typeId === "5" && { color: "black" }}
            link={`/map?typeId=${typeId}`}
            infoContext={data && data.tooltip}
            element={
              <i
                id="draggable"
                className="im im-icon-Double-Tap"
                style={{
                  fontSize: "32px",
                  minWidth: "32px",
                  cursor: "pointer",
                }}
              />
            }
            id={typeId}
          />

          <BoxSubTitle style={typeId === "5" ? { color: "black" } : {}}>
            <strong>
              {data.details[0].measurement && (
                <span>
                  {t("Τιμή δείκτη")}: {parseFloat(data.details[0].measurement)}
                  &nbsp;|&nbsp;
                </span>
              )}
              {data.details[0].index_title}
            </strong>
          </BoxSubTitle>
          <p style={{ fontSize: "14px", lineHeight: "1.5em" }}>
            {data.details[0].index_description}
          </p>
        </Fragment>
      )}

      {!data && <LoadingDot />}
    </Fragment>
  );
};

export default UvIndexes;
