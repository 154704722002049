export const informationLink = "https://www.smartiscity.gr/dimos-thermaikos/";
export const informationLinkEN =
  "https://www.smartiscity.gr/dimos-thermaikos/";

export const diavLink = "https://skoufas.diavouleuseis.gr";

export const applicantsCitizen = [
  {
    name: "Σύνδεση",
    url: "https://platform.cityzenapp.gr/cityzen/thermaikos",
  },
  {
    name: "Εγγραφή",
    url: "https://platform.cityzenapp.gr/cityzen/thermaikos/register",
  },
  // {
  //   name: "Πληροφορίες",
  //   url: "https://platform.cityzenapp.gr/cityzen/dimosskoufa/",
  // },
];

export const androidAppLink =
  "https://play.google.com/store/apps/details?id=gr.noveltech.cityzen.thermaikos&hl=el&gl=US&pli=1";
export const iosAppLink =
  "https://apps.apple.com/tt/app/%CF%80%CE%BF%CE%BB%CE%AF%CF%84%CE%B7%CF%82-%CE%B8%CE%B5%CF%81%CE%BC%CE%B1%CE%B9%CE%BA%CE%BF%CF%8D/id1487133921";

export const welcomeMessageContent = [
  "Σας καλωσορίζουμε στην Πλατφόρμα Εξυπνης Πόλης του Δήμου Θερμαϊκού.",
  "Ενημερωθείτε για τις συνθήκες που επικρατούν στην πόλη μας.",
];

export const baseUrl = "https://thermaikos.smartiscity.gr";

export const allTypesUrl = baseUrl + "/api/api.php?func=sensortypes";
export const allSensorsUrl = baseUrl + "/api/api.php?func=allsensors";

export const UvUrl = baseUrl + "/api/api.php?func=indexes";
export const enviURL = baseUrl + '/api/api.php?func=envi';
export const meshURL = baseUrl + "/api/api.php?func=mesh";
export const meteoURL = baseUrl + "/api/api.php?func=meteo";
export const poiURL =  baseUrl + "/api/api.php?func=pois";
export const fleetoUrl = baseUrl + "/api/api.php?func=fleeto";
export const binsUrl = baseUrl + "/api/api.php?func=bins";
export const hydroUrl = baseUrl + "/api/api.php?func=hydro";
export const weatherUrl = baseUrl + "/api/api.php?func=forecast";
export const wifiUrl = baseUrl + "/api/api.php?func=hotspot";
export const parkingUrl = baseUrl + "/api/api.php?func=parkingAll";
export const allBinsCharts = baseUrl + "/api/api.php?func=allbinstats";
export const specificBinChart = baseUrl + "/api/api.php?func=binstats";

export const chartsUrls = baseUrl + "/api/api.php?func=stats";

export const gallerySmall = baseUrl + "/gallery/vars/Small/";
export const galleryBig = baseUrl + "/gallery/vars/Big/";

export const cityCenter = {
  // lat: 40.62792947617496,
  // lng: 22.444795864427686,
  lat: 40.50237612820603,
  lng:  22.921787852227137
};

// settings
export const showEnvironmentAddress = true;
